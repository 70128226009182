<template>
	<div class="page">
		<a class="flex" href="../showVideo.html">
			<img src="../../assets/Tutorials-log.png" class="fixed_btn flex-right"/>
		</a>
		<div class="flex column h-center">
			<div class="relative mt-70">
				<img v-if="!robot.robotWxId"
				     src="../../assets/avatar.png"
				     class="avatar">
				<img v-if="robot.robotWxId"
				     :src="robot.headImgUrl"
				     class="avatar">
				<div v-if="robot.robotWxId && robot.state === 0"
				     class="live_out flex h-center">
					已离线
				</div>
			</div>

			<div class="fs-18 mt-2 nickname">{{ robot.nickName ? robot.nickName : '播客机器人' }}</div>
			<div class="color-gray fs-14 text-center">{{ userCode }}</div>
		</div>

		<div v-if="robot.robotWxId && robot.state === 0">
			<button class="btn mt-8"
			        @click="toLogin(2)">重新登录
			</button>

			<span class="fs-14 mt-4 color-danger flex h-center"
			      @click="logoutDialogVisible = true">解绑此微信号</span>
		</div>
		<RiskAvoidanceGuide class="mt-10"/>
		<logout-dialog v-model="logoutDialogVisible" key="showDeleteGroup" @cancel="logoutDialogVisible = false"
		               @confirm="logoutWx">
			<p class="text-center">微信号解绑后，微信的相关设置将会清除，是否确定解绑</p>
		</logout-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Notify, Toast } from 'vant'
import { defineAsyncComponent } from 'vue'
import { ApiLogin } from '../../api/User'
import { ApiGetPorts, ApiWxRobotLogout } from '../../api/WxRobot'
import { getParams, getUrlParams, hasUrlParameter } from '../../utils'

export default {
	name: 'reRegister',
	components: {
		RiskAvoidanceGuide: defineAsyncComponent(() => import('../../components/RiskAvoidanceGuide')),
		LogoutDialog: defineAsyncComponent(() => import('../../components/LogoutDialog'))

	},
	data() {
		return {
			loading: false,
			userCode: '',
			logoutDialogVisible: false
		}
	},
	computed: {
		userInfo() {
			return this.$store.getters.userInfo
		},
		robot() {
			return this.$store.getters.robotInfo
		}
	},

	mounted() {
		this.userCode = this.userInfo.userCode
	},
	methods: {
		async getPort() {
			try {
				const res = await ApiGetPorts()
				this.$store.dispatch('setRobotInfo',  res[0])
				await this.$router.push('login')
			} catch (e) {
				if (e === -10001) {
					await this.$router.replace('/login/noRobot')
				}
				console.log(e)
			}
		},

		toLogin(value) {
			const _this = this
			_this.$router.push('/login/login')
		},
		async logoutWx() {
			try {
				await ApiWxRobotLogout({
					robotWxId: this.robot.robotWxId,
					deviceId: this.robot.deviceId,
					wxType: 0,
					reason: [
						{
							reasonType: 1,
							reasonData: '默认'
						}
					]
				})
				await this.getPort()
			} catch (e) {
				console.log(e)
			}
		}
	}
}
</script>

<style scoped lang="scss">

.risk-avoidance-guide {
	position: absolute;
	bottom: px(20);
	left: 0;
	right: 0;
}

.login-explain__list {
	color: #433C3C;
	align-items: baseline;

	.context--answer {
		color: rgba(67, 60, 60, 0.5);
	}
}

.page {
	position: relative;
	padding: px(20) px(30);
	background-color: #fff;

	.avatar {
		width: px(80);
		height: px(80);
		border-radius: 50%;
	}

	.nickname {
		color: #433C3C;
	}

	.btn {
		font-size: px(14);
		font-weight: 500;
		color: #fff;

		width: 90%;
		height: px(40);
		margin: 0 5%;
		background: #FF445E;
		box-shadow: 0 px(2) px(6) 0 rgba(0, 0, 0, 0.12);
		border-radius: px(6);
	}

	.live_out {
		position: absolute;
		top: 0;
		left: 0;
		width: px(80);
		height: px(80);
		font-size: px(12);
		color: #fff;
		text-align: center;
		border-radius: 50%;
		background: rgba(0, 0, 0, 0.3);
	}


	.mt-70 {
		margin-top: px(70);
	}

	.mt-30 {
		margin-top: px(30);
	}

	.mt-115 {
		margin-top: px(115);
	}

	.mt-130 {
		margin-top: px(130);
	}

	.mt-280 {
		margin-top: px(280);
	}


}

.fixed_btn {
	position: absolute;
	top: px(20);
	right: px(20);
	width: px(40);
	height: px(40);
	z-index: 1;
}

</style>
